<template>
  <div class="help-add-music-component">
    <div>1. 搜索对应歌曲网络资源链接</div>
    <div>2. 点击歌曲列表, 点击添加图标</div>
    <div>3. 输入音乐信息, 提交即可添加至播放列表</div>
    <div>4. 也可通过下载json依据对应信息格式添加即可, 之后再歌曲列表点击上传图标上传文件即可添加至播放列表</div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive
} from 'vue'

export default defineComponent({
  name: 'help-add-music',
  title: '如何添加音乐？',

  setup (props, context) {
    const state = reactive({})

    return {
      state
    }
  }
})
</script>
<style lang="scss" scoped>
.help-add-music-component {

}
</style>
