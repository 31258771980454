<template>
  <div class="help-copy-right-component">
    <div>如有侵权，请联系本人予以删除或修改！</div>
    <div>邮箱：1071126434@qq.com</div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive
} from 'vue'

export default defineComponent({
  name: 'help-copy-right',
  title: '如有侵权, 请联系我',

  setup (props, context) {
    const state = reactive({})

    return {
      state
    }
  }
})
</script>
<style lang="scss" scoped>
.help-copy-right-component {
}
</style>
