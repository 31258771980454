<template>
  <div class="help-feed-back-component">
    <div>邮箱：1071126434@qq.com</div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive
} from 'vue'

export default defineComponent({
  name: 'help-feed-back',
  title: '问题 or 意见反馈 or 邮件留言',

  setup (props, context) {
    const state = reactive({})

    return {
      state
    }
  }
})
</script>
<style lang="scss" scoped>
.help-feed-back-component {
  
}
</style>
