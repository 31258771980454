<template>
  <div class="help-me">
    <van-nav-bar title="帮助中心">
      <template #left>
        <van-icon name="arrow-left" size="1.8rem" color="rgba(0, 0, 0, 0.8)" @click="goBack" />
      </template>
    </van-nav-bar>

    <!-- 展示帮助列表 -->
    <div class="help-list">
      <van-collapse v-model="state.activeHelpName" accordion>
        <van-collapse-item
          v-for="(com, key) in HelpComponents"
          :key="key"
          :title="com.title || key"
          :name="key"
        >
          <component :is="key" />
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive
} from 'vue'
import {
  NavBar,
  Icon,
  Collapse,
  CollapseItem
} from 'vant'
import { useRouter } from 'vue-router'
import HelpComponents from './helps/import-index'

export default defineComponent({
  name: 'help-me',

  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    ...HelpComponents
  },

  setup (props, context) {
    const router = useRouter()
    const state = reactive({
      activeHelpName: 'add-music'
    })

    // 返回
    function goBack () {
      if (window.history.length <= 2) {
        router.replace('/')
      } else {
        router.back()
      }
    }

    return {
      state,
      goBack,
      HelpComponents
    }
  }
})
</script>
<style lang="scss" scoped>
.help-me {
  .help-list {
    margin: 1rem;
    border-radius: 0.4rem;
    overflow: hidden;
  }
}
</style>
